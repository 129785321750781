import React from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";

import ProductQuickView from "../../components/product-quick-view/product-quick-view";
import PhotoGallery from "../../components/photo-gallery/photo-gallery";
import PageSection from "../../components/page-section/page-section";

import useProducts from "../../hooks/use-products";
import { getShortDescription } from "../../utils/product-utils";

import "./home-page.scss";

const HomePage = () => {
  const { products = [] } = useProducts();
  const product = products.find((p) => p.productType === "Turbo Kits");
  const { variants } = product || {};
  const [firstVariant] = variants || [];
  let featuredPrice = null;

  if (firstVariant) {
    featuredPrice = {
      original: firstVariant.compareAtPrice,
      markdown: firstVariant.price,
    };
  }

  return (
    <>
      <Helmet>
        <title>Remnant Performance</title>
      </Helmet>

      <PageSection className="home-section">
        <span className="large-logo fade-in"></span>
      </PageSection>
      <PageSection>
        {product && (
          <ProductQuickView
            isNew={product.isNew}
            images={[product.images[0]]}
            name={product.title}
            price={featuredPrice}
            description={getShortDescription(product.descriptionHtml)}
            detailUrl={`/products/${product.parentCategorySlug}/${product.handle}`}
          />
        )}

        <hr className="section-divider" />

        <div className="services">
          <div className="services__card">
            <h4 className="services__heading">
              As an authorized tune dealer, we offer AlphaSpeed tuning services
              to a variety of vehicles in the North Carolina area including:
            </h4>

            <ul className="services__list">
              <li>Hyundai Genesis Coupe 2.0T & 3.8 AT and MT</li>
              <li>Hyundai Sonata/Kia Optima</li>
              <li>Scion/Toyota/Subaru FRS/BRZ</li>
              <li>Mitsubishi EVO</li>
              <li>Nissan 2013+ Vehicles</li>
              <li>
                Various Ford vehicles including the Mustang, Fiesta, Focus and
                F150’s etc
              </li>
              <li>
                Various Chevy vehicles including the Camaro, Corvette,
                Silverados etc
              </li>
              <li>New A90 Toyota Supra</li>
              <li>Various BMW Models</li>
              <li>And More...</li>
            </ul>
            <p className="services__more-info">
              For more information about tuning, including the process and
              pricing, please check out the{" "}
              <Link to="/tuning">tuning page</Link>. AlphaSpeed can tune most
              modern vehicles, the above list is just a few short examples of
              what we can tune. Please feel free to contact us through Facebook,
              Instagram, phone or email to discuss the specific tuning needs for
              your vehicle
            </p>
          </div>
        </div>
      </PageSection>
      <PageSection className="gallery-section">
        <PhotoGallery />
      </PageSection>
    </>
  );
};

export default HomePage;
