import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import axios from "axios";

import Page from "../../components/page/page";

import "./news-page.scss";

const formattedMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const NewsPage = () => {
  const [data, setData] = useState(null);

  async function getInstagramData() {
    try {
      const resp = await axios.get(
        "https://www.instagram.com/remnantperformance?__a=1"
      );

      if (
        (resp &&
          resp.data &&
          resp.data.graphql &&
          resp.data.graphql.user &&
          resp.data.graphql.user.edge_owner_to_timeline_media,
        resp.data.graphql.user.edge_owner_to_timeline_media.edges)
      ) {
        setData(resp.data.graphql.user.edge_owner_to_timeline_media.edges);
      }
    } catch (err) {
      console.error("Issue fetching Instagram stories |", err);
    }
  }

  useEffect(() => {
    getInstagramData();
  }, []);

  return (
    <Page title="Latest News">
      <Helmet>
        <title>News | Remnant Performance</title>
      </Helmet>

      {Array.isArray(data) && (
        <ul className="news-list">
          {data.map((s, idx) => {
            const story = s.node || {};
            const imgSrc = story.display_url;
            const postDate = story.taken_at_timestamp
              ? new Date(story.taken_at_timestamp * 1000)
              : new Date();
            const postDay = postDate.getDate();
            const postMonth = formattedMonths[postDate.getMonth()];
            const postYear = postDate.getFullYear();
            let caption = "";

            if (
              Array.isArray(story.edge_media_to_caption.edges) &&
              story.edge_media_to_caption.edges[0] &&
              story.edge_media_to_caption.edges[0].node
            ) {
              caption = story.edge_media_to_caption.edges[0].node.text;
            }

            return (
              <li key={`igs-${idx}`} className="news-list-item">
                <img src={imgSrc} />
                <p>
                  <span className="news-list-item__date">
                    {postMonth} {postDay}, {postYear}
                  </span>
                  {caption}
                </p>
              </li>
            );
          })}
        </ul>
      )}
    </Page>
  );
};

export default NewsPage;
