import React, { useState } from "react";

import "./product-gallery.scss";

const ProductGallery = ({ images = [] }) => {
  const [imageIdx, setImageIdx] = useState(0);

  if (!Array.isArray(images)) return null;

  return (
    <div className="product-gallery">
      <div
        className="product-gallery__image"
        style={{ backgroundImage: `url(${images[imageIdx].src})` }}
      ></div>
      {images.length > 1 && (
        <ul className="product-gallery__thumbnails">
          {images.map((img, idx) => (
            <li
              key={`pgt-${idx}`}
              className={`product-gallery__thumbnail ${
                imageIdx === idx ? "product-gallery__thumbnail--active" : ""
              }`}
            >
              <button
                type="button"
                className="product-gallery__thumbnail-button"
                style={{ backgroundImage: `url(${img.src})` }}
                onClick={() => setImageIdx(idx)}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ProductGallery;
