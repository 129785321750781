import React from "react";

import "./quantity.scss";

function Quantity(props) {
  const { onBlur, onChange, value } = props;

  return (
    <input
      className="quantity-input"
      type="number"
      onChange={onChange}
      onBlur={onBlur}
      value={value}
    />
  );
}

export default Quantity;
