import React, { useState } from "react";
import { Link } from "react-router-dom";

import ProductGallery from "../product-gallery/product-gallery";
import CartItem from "../cart-item/cart-item";
import NewBadge from "../new-badge/new-badge";
import Price from "../price/price";
import Button from "../button/button";
import Quantity from "../quantity/quantity";
import Modal from "../modal/modal";

import useCheckout from "../../hooks/use-checkout";

import "./product-quick-view.scss";

function ProductQuickView(props) {
  const {
    images,
    isNew,
    price,
    description,
    detailUrl,
    name,
    id,
    options,
    onSelectOption,
    selectedOption,
    showAddToCart
  } = props;

  const { addToCart } = useCheckout();
  const [quantity, setQuantity] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);

  function onQuantityChange(e) {
    const { value } = e.target;

    if (value !== "") {
      const qty = parseInt(value);
      if (qty < 100) {
        setQuantity(qty);
      }
    } else {
      setQuantity("");
    }
  }

  function onQuantityBlur() {
    if (quantity < 1) {
      setQuantity(1);
    }
  }

  function toggleModal() {
    setIsModalOpen(!isModalOpen);
  }

  function handleAddToCart() {
    addToCart(id, quantity);
    setIsModalOpen(true);
  }

  return (
    <div className="product-detail">
      {detailUrl ? (
        <Link className="product-detail__display" to={detailUrl}>
          {isNew && <NewBadge />}
          {Array.isArray(images) && <ProductGallery images={images} />}
        </Link>
      ) : (
        <div className="product-detail__display">
          {isNew && <NewBadge />}
          {Array.isArray(images) && <ProductGallery images={images} />}
        </div>
      )}

      <div className="product-detail__details">
        <h2 className="product-detail__name">{name}</h2>
        {price && (
          <Price value={price.original} markdownValue={price.markdown} />
        )}

        {description && (
          <p
            className="product-detail__description"
            dangerouslySetInnerHTML={{ __html: description }}
          ></p>
        )}

        {showAddToCart && (
          <>
            {Array.isArray(options) && (
              <label className="product-quick-view__options-label">
                <span className="product-quick-view__options-label-name">
                  Options
                </span>
                <div className="product-quick-view__options-wrapper">
                  <select
                    className="product-quick-view__options"
                    onChange={onSelectOption}
                  >
                    {options.map((option, idx) => (
                      <option key={`vo-${idx}`} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </label>
            )}

            <div className="product-quick-view__action">
              <Quantity
                onChange={onQuantityChange}
                onBlur={onQuantityBlur}
                value={quantity}
              />
              <Button
                className="product-detail__button"
                primary
                onClick={handleAddToCart}
              >
                Add To Cart
              </Button>
            </div>
          </>
        )}

        {detailUrl && !showAddToCart && (
          <div className="product-quick-view__action">
            <Button href={detailUrl} className="product-detail__button" primary>
              View Details
            </Button>
          </div>
        )}
      </div>

      <Modal
        isOpen={isModalOpen}
        heading="Added Item To Cart"
        onClose={toggleModal}
      >
        <div className="product-quick-view__modal-product">
          <CartItem
            productName={name}
            selectedOption={
              Array.isArray(options) && options.length > 1
                ? selectedOption
                : null
            }
            image={Array.isArray(images) ? images[0] : null}
            price={price}
            quantity={quantity}
          />
        </div>

        <div className="product-quick-view__modal-actions">
          <Button onClick={toggleModal}>Continue Shopping</Button>
          <Button primary href="/cart">
            View Cart & Checkout
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default ProductQuickView;
