import React from "react";
import ReactModal from "react-modal";

import "./modal.scss";

ReactModal.setAppElement("#root");
ReactModal.defaultStyles.content = null;
ReactModal.defaultStyles.overlay = null;

const Modal = props => {
  const {
    children,
    className = "",
    contentLabel,
    heading,
    isOpen,
    onClose,
    showHeader = true
  } = props;

  function onRequestClose() {
    const scrollY = window.scrollY;

    onClose();
    window.scrollTo(0, scrollY);
  }

  return (
    <ReactModal
      contentLabel={
        !contentLabel && typeof heading === "string"
          ? `${heading} Modal`
          : contentLabel
      }
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={"modal-container " + className}
      overlayClassName={className}
    >
      {showHeader && (
        <header className={"modal-header " + className}>
          {typeof heading === "string" && (
            <span className="modal-header-title">{heading}</span>
          )}
          {typeof heading === "function" && heading()}
          <button
            className="modal-close"
            type="button"
            onClick={onRequestClose}
          >
            &times;
          </button>
        </header>
      )}
      <div className={"modal-content-container " + className}>{children}</div>
    </ReactModal>
  );
};

export default Modal;
