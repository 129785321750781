import React from "react";

import "./price.scss";

const Price = (props) => {
  const { markdownValue, value } = props;
  return (
    <div className="price">
      {markdownValue && (
        <span className="price__markdown">
          ${markdownValue.amount.toLocaleString("en-US")}
        </span>
      )}
      <span
        className={
          markdownValue ? "price__value price__value--markdown" : "price__value"
        }
      >
        ${value.amount.toLocaleString("en-US")}
      </span>
    </div>
  );
};

export default Price;
