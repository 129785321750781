import React from "react";
import Helmet from "react-helmet";

import Page from "../../components/page/page";
import Button from "../../components/button/button";
import CartItem from "../../components/cart-item/cart-item";

import useCheckout from "../../hooks/use-checkout";

import "./cart-page.scss";

const CartPage = () => {
  const { checkout, updateQuantity, removeItem } = useCheckout();
  const { subtotalPrice, totalPrice, lineItems, webUrl } = checkout || {};
  const hasItems = Array.isArray(lineItems) && lineItems.length > 0;

  function onQuantityChange(quantity, productId) {
    if (quantity === 0) {
      removeItem(productId);
    } else if (typeof quantity === "number") {
      updateQuantity(productId, quantity);
    }
  }

  return (
    <Page title="Cart">
      <Helmet>
        <title>Cart | Remnant Performance</title>
      </Helmet>

      {checkout && hasItems && (
        <div className="cart-page">
          <ul className="cart-page__list">
            {lineItems.map((item, idx) => {
              const { variant = {}, title, quantity, id } = item;
              const selectedOption = variant.title;
              const markdown = variant.price;
              const original = variant.compareAtPrice;
              const image = variant.image || null;

              return (
                <li className="cart-page__list-item" key={`cl-${idx}`}>
                  <button
                    className="cart-page__list-item-remove"
                    type="button"
                    onClick={() => {
                      removeItem(id);
                    }}
                  >
                    Remove
                  </button>
                  <CartItem
                    productName={title}
                    image={image}
                    selectedOption={selectedOption}
                    price={{ markdown, original }}
                    onUpdateQuantity={(qty) => onQuantityChange(qty, id)}
                    quantity={quantity}
                  />
                </li>
              );
            })}
          </ul>
          <div className="cart-page__summary">
            <strong>
              Subtotal: ${Number(subtotalPrice.amount).toFixed(2)}
            </strong>
            <br />
            <em>Shipping: not calculated</em>
            <br />
            <em>Taxes: not calculated</em>
            <br />
            <span className="cart-page__summary-total">
              Total: ${Number(totalPrice.amount).toFixed(2)}
            </span>
            <Button primary href={webUrl}>
              Checkout
            </Button>
          </div>
        </div>
      )}

      {!hasItems && (
        <div className="cart-page">
          <div className="cart-page__empty">
            <p className="cart-page__empty-message">You're cart is empty.</p>
            <Button primary href="/products">
              Continue Shopping
            </Button>
          </div>
        </div>
      )}
    </Page>
  );
};

export default CartPage;
