import React, { useState } from "react";

const AppContext = React.createContext();

export function AppProvider(props) {
  const { children } = props;
  const [products, setProducts] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [checkout, setCheckout] = useState(null);

  return (
    <AppContext.Provider
      value={{
        checkout,
        productCategories,
        products,
        setCheckout,
        setProductCategories,
        setProducts
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export default AppContext;
