import React from "react";

import Page from "../../components/page/page";

const ThankYouPage = props => {
  const { order } = props;
  const { id, payer, purchase_units = [] } = order || {};
  let payerName = "";

  const orderDetails = purchase_units[0];
  const shippingAddress =
    orderDetails && orderDetails.shipping && orderDetails.shipping.address
      ? orderDetails.shipping.address
      : null;

  if (payer && payer.name) {
    payerName = `${payer.name.given_name}${
      payer.name.surname ? ` ${payer.name.surname}` : ""
    }`;
  }

  return (
    <Page title="Order Confirmation">
      <div className="copy" style={{ textAlign: "center" }}>
        {payer && payer.name && payer.name.given_name ? (
          <h2>Thank You {payerName} for Your Purchase!</h2>
        ) : (
          <h2>Thank You for Your Purchase!</h2>
        )}

        {id && (
          <>
            <strong>Order Id:</strong>
            <br />
            {id}
          </>
        )}
        <br />
        <br />

        {orderDetails && orderDetails.shipping && (
          <>
            <strong>Shipping Info</strong>
            <address>
              <span>{orderDetails.shipping.name.full_name}</span>
              <br />
              {shippingAddress && (
                <span>
                  {shippingAddress.address_line_1}
                  {shippingAddress.admin_area_2}, {shippingAddress.admin_area_1}{" "}
                  {shippingAddress.postal_code}
                </span>
              )}
            </address>
          </>
        )}

        <p>
          Please follow the link below to take you to Performance Unleashed
          where you can purchase your gauge kit!
        </p>

        <a
          href="https://www.performanceunleashedna.com/turbokit-gauges"
          rel="noreferrer noopener"
        >
          https://www.performanceunleashedna.com/turbokit-gauges
        </a>
      </div>
    </Page>
  );
};

export default ThankYouPage;
