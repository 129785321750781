import React, { useContext, useEffect } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";

import HomePage from "./pages/home-page/home-page";
import ProductListPage from "./pages/product-list-page/product-list-page";
import ProductDetailPage from "./pages/product-detail-page/product-detail-page";
import ProductCategoryPage from "./pages/product-category-page/product-category-page";
import AboutPage from "./pages/about-page/about-page";
import CartPage from "./pages/cart-page/cart-page";
import NewsPage from "./pages/news-page/news-page";
import TermsPage from "./pages/terms-page/terms-page";
import ContactPage from "./pages/contact-page/contact-page";
import ThankYouPage from "./pages/thank-you-page/thank-you-page";
import TuningPage from "./pages/tuning-page/tuning-page";
import ErrorPage from "./pages/error-page/error-page";

import AppContext from "./app-context";

function ProtectedRoute(props) {
  const { children: Component } = props;
  const context = useContext(AppContext);

  return context.order ? (
    <Component order={context.order} />
  ) : (
    <Navigate to="/" />
  );
}

function JumpToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}

function AppRoutes() {
  return (
    <>
      <JumpToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/products" element={<ProductCategoryPage />} />
        <Route path="/products/:categoryId" element={<ProductListPage />} />
        <Route
          path="/products/:category/:productId"
          element={<ProductDetailPage />}
        />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/news" element={<NewsPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/terms-and-conditions" element={<TermsPage />} />
        <Route path="/cart" element={<CartPage />} />
        <Route
          path="/thank-you"
          element={
            <ProtectedRoute>
              <ThankYouPage />
            </ProtectedRoute>
          }
        />
        <Route path="/tuning" element={<TuningPage />} />
        <Route element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default AppRoutes;
