import React, { useState } from "react";

import Price from "../price/price";
import Quantity from "../quantity/quantity";

import "./cart-item.scss";

function CartItem(props) {
  const {
    image,
    productName,
    selectedOption,
    price,
    quantity,
    onUpdateQuantity,
  } = props;

  const [itemQuantity, setItemQuantity] = useState(quantity);

  function onQuantityChange(e) {
    const { value } = e.target;

    if (value !== "") {
      const qty = parseInt(value);
      if (qty < 100) {
        setItemQuantity(qty);
        if (onUpdateQuantity) {
          onUpdateQuantity(qty);
        }
      }
    } else {
      setItemQuantity("");
    }
  }

  function onQuantityBlur(e) {
    const { value } = e.target;

    if (value === "") {
      setItemQuantity(1);
      onUpdateQuantity(1);
    }
  }

  return (
    <div className="cart-item">
      {image && (
        <img className="cart-item__image" src={image.src} alt={image.alt} />
      )}
      <div className="cart-item__summary">
        <span className="cart-item__summary-name">{productName}</span>
        {selectedOption && (
          <span className="cart-item__summary-option">{selectedOption}</span>
        )}
        {price && (
          <Price value={price.original} markdownValue={price.markdown} />
        )}

        {quantity && onUpdateQuantity ? (
          <label className="cart-item__summary-qty-label">
            Quantity:&nbsp;
            <Quantity
              value={itemQuantity}
              onChange={onQuantityChange}
              onBlur={onQuantityBlur}
            />
          </label>
        ) : (
          <span className="cart-item__summary-qty">
            Quantity: <strong>{quantity}</strong>
          </span>
        )}
        {price && price.markdown && quantity && (
          <span className="cart-item__summary-subtotal">
            Subtotal:{" "}
            <strong>
              ${(Number(price.markdown.amount) * quantity).toFixed(2)}
            </strong>
          </span>
        )}
      </div>
    </div>
  );
}

export default CartItem;
