import React, { useState } from "react";

import Modal from "../modal/modal";

import "./photo-gallery.scss";

const PhotoGallery = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  function onImageClick(image) {
    setIsOpen(true);
    setSelectedImage(image);
  }

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  return (
    <div className="photo-gallery">
      {galleryImages.map((image, idx) => (
        <div
          key={`gi-${idx}`}
          className={`photo-gallery__tile ${
            idx === 2 ? "photo-gallery__tile--4tile" : ""
          }`}
          onClick={() => {
            onImageClick(image);
          }}
        >
          <div
            className={`photo-gallery__thumbnail`}
            style={{ backgroundImage: `url(${image.src})` }}
          />
        </div>
      ))}

      <Modal isOpen={isOpen} onClose={toggleModal}>
        {selectedImage && selectedImage.src && (
          <img
            className="photo-gallery__modal-image"
            src={selectedImage.src}
            alt={selectedImage.alt}
          />
        )}
      </Modal>
    </div>
  );
};

const galleryImages = [
  { src: "/images/gallery-preview_2.jpg", alt: "placeholder" },
  {
    src: "/images/gallery-preview_7.jpg",
    alt: "Turbo gages",
  },
  {
    src: "/images/gallery-preview_6.jpg",
    alt: "placeholder",
  },
  {
    src: "/images/gallery-preview_1.jpg",
    alt: "Hood clearance",
  },
  {
    src: "/images/gallery-preview_3.jpg",
    alt: "placeholder",
  },
  {
    src: "/images/gallery-preview_4.jpg",
    alt: "placeholder",
  },
  {
    src: "/images/gallery-preview_5.jpg",
    alt: "Blow off valve",
  },
];

export default PhotoGallery;
