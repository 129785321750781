import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Helmet from "react-helmet";

import ProductQuickView from "../../components/product-quick-view/product-quick-view";
import Page from "../../components/page/page";

import {
  getLongDescription,
  getShortDescription,
} from "../../utils/product-utils";
import useProducts from "../../hooks/use-products";

import "./product-detail-page.scss";

function ProductDetailPage() {
  const navigate = useNavigate();
  const params = useParams();
  const { products, isLoading } = useProducts();
  const [isNotFound, setIsNotFound] = useState(false);
  const [product = {}, setProduct] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState({});

  const { productType, title, variants, isNew, availableForSale } =
    product || {};
  const { id: variantId, price, compareAtPrice } = selectedVariant || {};
  const variantOptions =
    Array.isArray(variants) && variants.length > 1
      ? variants.map((v) => ({ name: v.title, value: v.sku }))
      : null;

  function onSelectOption(e) {
    const { value: selectedSku } = e.target;

    const variant = variants.find((v) => v.sku === selectedSku);

    if (variant) {
      setSelectedVariant(variant);
    }
  }

  useEffect(() => {
    if (!isLoading && Array.isArray(products)) {
      const selectedProduct = products.find(
        (p) => p.handle === params.productId
      );

      if (selectedProduct) {
        setProduct(selectedProduct);
        if (
          Array.isArray(selectedProduct.variants) &&
          selectedProduct.variants.length > 0
        ) {
          setSelectedVariant(selectedProduct.variants[0]);
        }
      } else {
        setIsNotFound(true);
      }
    }
  }, [isLoading]);

  if (isNotFound) {
    navigate("/not-found", { replace: true });
    return null;
  }

  return (
    <Page>
      <Helmet>{title && <title>{title} | Remnant Performance</title>}</Helmet>

      {product && selectedVariant && (
        <div className="product-detail-container">
          <ProductQuickView
            id={variantId}
            isNew={isNew}
            price={{ original: compareAtPrice, markdown: price }}
            name={title}
            description={getShortDescription(product.descriptionHtml)}
            options={variantOptions}
            selectedOption={selectedVariant ? selectedVariant.title : null}
            onSelectOption={onSelectOption}
            images={product.images}
            showAddToCart={availableForSale}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: getLongDescription(product.descriptionHtml),
            }}
          />
        </div>
      )}
    </Page>
  );
}

export default ProductDetailPage;
