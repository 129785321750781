import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import useCheckout from "../../hooks/use-checkout";

import "./header.scss";
import icon from "../../remnant_icon.png";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { pathname } = useLocation();
  const { checkout, itemCount } = useCheckout();
  const { lineItems } = checkout || {};

  useEffect(() => {
    if (showMenu) {
      document.body.style = "position: fixed;";
    }
    return () => {
      document.body.style = "";
    };
  }, [showMenu]);

  return (
    <header className="header">
      <Link to="/" onClick={() => setShowMenu(false)}>
        <img
          className="mobile-logo"
          src={icon}
          alt="Remnant Performance Logo"
        />
      </Link>
      <button
        className="mobile-menu-button"
        type="button"
        onClick={() => setShowMenu(!showMenu)}
      >
        <div className="menu-icon">
          <span></span>
          <span></span>
          <span></span>
        </div>
        Menu
      </button>
      <nav className="main-nav" style={!showMenu ? { display: "none" } : null}>
        <button
          className="close-menu-button"
          type="button"
          onClick={() => setShowMenu(!showMenu)}
        >
          &times;
        </button>
        <Link className="nav-icon" to="/" onClick={() => setShowMenu(false)}>
          <img className="icon" src={icon} />
        </Link>
        <a className="mobile-menu-phone" href="tel:336.360.8780">
          <i className="icon-phone phone-number__icon" />
          336.360.8780
        </a>
        <Link
          className={
            pathname === "/products" ? "nav-link nav-link--active" : "nav-link"
          }
          to="/products"
          onClick={() => setShowMenu(false)}
        >
          Products
        </Link>
        <Link
          className={
            pathname === "/tuning" ? "nav-link nav-link--active" : "nav-link"
          }
          to="/tuning"
          onClick={() => setShowMenu(false)}
        >
          Tuning
        </Link>
        <Link
          className={
            pathname === "/about" ? "nav-link nav-link--active" : "nav-link"
          }
          to="/about"
          onClick={() => setShowMenu(false)}
        >
          About
        </Link>
        <Link
          className={
            pathname === "/news" ? "nav-link nav-link--active" : "nav-link"
          }
          to="/news"
          onClick={() => setShowMenu(false)}
        >
          News
        </Link>
        <Link
          className={
            pathname === "/contact" ? "nav-link nav-link--active" : "nav-link"
          }
          to="/contact"
          onClick={() => setShowMenu(false)}
        >
          Contact
        </Link>
      </nav>

      <Link to="/cart" className="cart-button">
        <i className="cart-button__icon icon-cart" />
        {itemCount > 0 && (
          <span className="cart-button__item-count">{itemCount}</span>
        )}
      </Link>
    </header>
  );
};

export default Header;
