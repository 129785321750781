import React from "react";
import Helmet from "react-helmet";

import Page from "../../components/page/page";
import PageSection from "../../components/page-section/page-section";

import "./contact-page.scss";

const ContactPage = () => {
  return (
    <Page title="Contact Us">
      <Helmet>
        <title>Contact | Remnant Performance</title>
      </Helmet>
      <PageSection className="contact-page-container">
        <p className="contact-page-intro">
          Thank you for choosing <strong>Remnant Performance</strong>! We look
          forward to hearing from you. Please contact us through one of the
          following:
        </p>
        <ul className="contact-options">
          <li>
            <strong>Email:</strong>
            &nbsp;
            <a href="mailto:sales@remnantperformance.com">
              sales@remnantperformance.com
            </a>
          </li>
          <li>
            <strong>Phone:</strong>
            &nbsp;
            <a href="tel:336-360-8780">(336) 360-8780</a>
          </li>
          <li>
            <strong>Social:</strong>
            &nbsp;
            <a href="https://www.facebook.com/remnantperformance">Facebook</a>
            &nbsp;or&nbsp;
            <a href="https://www.instagram.com/remnantperformance/">
              Instagram
            </a>
          </li>
        </ul>
      </PageSection>
    </Page>
  );
};

export default ContactPage;
