import { useState, useEffect, useContext } from "react";

import client from "../shopfiy-client";
import AppContext from "../app-context";

function useProducts() {
  const { products, productCategories, setProducts, setProductCategories } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(products.length < 1);

  async function getProducts() {
    if (products.length < 1) {
      try {

        const [products, collections] = await Promise.all([client.product.fetchAll(), client.collection.fetchAllWithProducts()]);
        const collectionProductIds = new Set();
        const collectionProducts = [];
        const categories = [];

        if (collections) {
          for (const collection of collections) {
            const parentCategory = {
              title: collection.title,
              slug: collection.title.toLowerCase().replace('&', 'and').replace(/\s+/g, '-')
            };

            categories.push(parentCategory);

            if (collection.products) {
              for (const product of collection.products) {
                if (product && !collectionProductIds.has(product.id)) {
                  const newProduct = { ...product, isNew: collection.handle === 'new', parentCategorySlug: parentCategory.slug };

                  collectionProducts.push(newProduct);
                  collectionProductIds.add(newProduct.id);
                }
              }
            }
          }
        }
        
        setProductCategories(categories);
        setProducts([...collectionProducts, ...products.filter(p => !collectionProductIds.has(p.id))]);
        setIsLoading(false);
      } catch (err) {
        console.error(err);
      }
    }
  }

  useEffect(() => {
    getProducts();
  }, []);

  return {
    productCategories,
    products,
    isLoading
  };
}

export default useProducts;
