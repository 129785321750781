import React from "react";

import "./page-section.scss";

function PageSection(props) {
  const { children, className = "" } = props;

  return (
    <section className={"section-container " + className}>{children}</section>
  );
}

export default PageSection;
