import React from 'react';

import Page from '../../components/page/page'
import Button from '../../components/button/button';

import useProducts from "../../hooks/use-products";

import './product-category-page.scss'

function ProductCategoryPage() {

    const { productCategories } = useProducts();

    return (
        <Page title="Products">
        <ul className="product-category-list">
            {Array.isArray(productCategories) && productCategories.map((category) => (
                <li><Button href={`/products/${category.slug}`}>{category.title}</Button></li>
            ))}
        </ul>
        <Button className="view-all-categories-btn" href="/products/all">View All Products</Button>
        </Page>
    )
}

export default ProductCategoryPage;