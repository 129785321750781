import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Routes from "./routes";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import { AppProvider } from "./app-context";

import "./app.scss";

function App() {
  return (
    <AppProvider>
      <Router>
        <Header />
        <main>
          test
          <Routes />
        </main>
        <Footer />
      </Router>
    </AppProvider>
  );
}

export default App;
