import React from "react";
import Helmet from "react-helmet";

import Page from "../../components/page/page";

import "./tuning-page.scss";

function TuningPage() {
  return (
    <Page title="AlphaSpeed Tuning">
      <Helmet>
        <title>AlphaSpeed Tuning | Remnant Performance</title>
      </Helmet>

      <div className="copy tuning-page">
        <section>
          <p>We at Remnant Performance are your local AlphaSpeed tune dealer in Holly Springs, NC</p>
          <heading>
            <h2>TUNE PRICING</h2>
          </heading>

          <h3>SHOW TUNE ($250)</h3>
          <ul>
            <li>Raised Rev Limiter</li>
            <li>Pops and Bangs</li>
            <li>Launch Control aka Two-Step</li>
            <li>Retained factory octane compatibility</li>
            <li>NO power increases</li>
          </ul>

          <h3>GO TUNE ($550) - 91 / 93 Octane only</h3>
          <ul>
            <li>Benefits of “Show” Tune</li>
            <li>Prepackaged tune, power increased</li>
            <li>Power increase (ex: Timing and boost)</li>
            <li>Improved throttle response</li>
          </ul>

          <h3>CUSTOM TUNE ($650) - 91 / 93 Octane only</h3>
          <ul>
            <li>Benefits of “Show” & “Go”Tunes</li>
            <li>
              Max power increase by adjusting all parameters to their highest
              safe values (Timing, boost, VVT, Throttle etc)
            </li>
          </ul>

          <h3>RETUNE</h3>
          <p>$250 + price difference between previous tune and custom tune</p>

          <h3>TUNE ADD-ONS</h3>
          <ul>
            <li>Non-Stock injectors (+$100)</li>
            <li>E85 (+$150) / Flex Fuel (+$250)</li>
            <li>Nitrous (+$150)</li>
            <li>Big Turbo / Supercharger (+$250)</li>
            <li>Non-stock MAP sensor (+$100)</li>
          </ul>
        </section>

        <h3>TRANSMISSION TUNING</h3>
        <p>AlphaSpeed offers transmission tuning for many Automatic Transmissions in two stages:</p>
        <ul>
          <li>
            Stage 1 ($150)
            <ul className="customer-info">
                <li>Increased rev limiter</li>
                <li>Increased shift points so you can rev out a gear higher and take advantage of a longer powerband in full auto mode</li>
                <li>Disabled auto up-shift in manual mode</li>
            </ul>
          </li>
          <li>
            Stage 2 ($250)
            <ul className="customer-info">
                <li>More aggressive/punchy shifts</li>
            </ul>
          </li>
        </ul>

        <section className="tuning-process-section">
          <heading>
            <h2>THE PROCESS</h2>
          </heading>

          <ol>
            <li>
              <p>
                Call / Text / DM us at Remnant Performance and provide us with
                the following information:
              </p>
              <ul className="customer-info">
                <li>First and Last name</li>
                <li>City & State of residence</li>
                <li>Email address</li>
                <li>
                  Car information (year, make, model, transmission type and key
                  start / push button start)
                </li>
                <li>
                  If you are doing a CUSTOM tune, please provide clear
                  description of modifications.
                </li>
              </ul>
            </li>
            <li>
              <p>
                Based on the above information, if you are close to us, we will
                proceed in finding a time slot on the AlphaSpeed tuning calendar
                that works with your schedule. If you aren’t close to us, we
                will give you the contact information for your nearest
                AlphaSpeed tune dealer
              </p>
            </li>
            <li>
              <p>
                Once we both agree on a time slot for your tune appointment, we
                will send you an invoice for the $100 tune deposit in order to
                schedule your tune. This deposit goes towards the price of your
                tune so you will only be responsible for the remainder on the
                day of your tune.
              </p>
            </li>
            <li>
              <p>
                Prior to arriving for your tune, make sure that your car is in
                correct working order. You do not want to show up for a custom
                tune and not be able to complete the process once you start due
                to a mechanical issue that you could have resolved. If we start
                tuning your car, you will be charged the full amount for the
                tune even if your car is having an issue that prevents us from
                fully completing the process. You will have to schedule and pay
                for a retune to continue tuning.
              </p>
            </li>
            <li>
              <p>
                On the day of your tune, plan to arrive at the location around
                5-10 minutes early. We will meet you and connect our tuning
                equipment to your car’s OBD port to start the tuning process.
              </p>
            </li>
            <li>
              <p>
                If you scheduled a SHOW or GO tune, we will load on file and
                then after showing you the features of your tune, the tuning
                process will be complete. Expect ~45 minutes
              </p>
            </li>
            <li>
              <p>
                If it is a CUSTOM tune, we will load a base map to your vehicle
                and then we will go on a drive to test that map. You will be
                expected to do a 3rd gear pull from ~3K rpms until redline at
                wide open throttle. During this test, we will be datalogging the
                values from your ECU. This information, couple with a video with
                auditory cues for rpm of your widband O2 gauge (if you have one)
                will be used to tweak your file and create a new map.
              </p>
            </li>
            <li>
              <p>
                We then will load the new map and repeat the above steps until
                we’ve fully dialed in the tune to take maximum advantage of your
                available modifications. Please expect the CUSTOM tune process
                to take ~1.5-2 hours
              </p>
            </li>
          </ol>
        </section>
      </div>
    </Page>
  );
}

export default TuningPage;
