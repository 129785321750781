import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import Helmet from "react-helmet";

import Page from "../../components/page/page";
import ProductQuickView from "../../components/product-quick-view/product-quick-view";

import { getShortDescription } from "../../utils/product-utils";
import useProducts from "../../hooks/use-products";

import "./product-list-page.scss";

function ProductListPage() {
  const { products, productCategories, isLoading } = useProducts();
  const { categoryId } = useParams();
  const navigate = useNavigate();
  const category = productCategories.find((c) => c.slug === categoryId);
  const categoryType = category ? category.title : "";
  const filteredProducts = products.filter((p) => {
    if (category && category.slug !== p.parentCategorySlug) {
      return false;
    }
    return true;
  });

  if (!isLoading && !category && categoryId !== "all") {
    navigate("/not-found", { replace: true });
  }

  return (
    <Page title={`${categoryType} Products`}>
      <Helmet>
        <title>{categoryType} Products | Remnant Performance</title>
      </Helmet>

      <ul className="list">
        {!isLoading &&
          Array.isArray(filteredProducts) &&
          filteredProducts.map((product, idx) => {
            const [firstVariant] = product.variants || [];
            let price = null;

            if (firstVariant) {
              price = {
                original: firstVariant.compareAtPrice,
                markdown: firstVariant.price,
              };
            }

            return (
              <li key={`pl-${idx}`} className="list-item">
                <ProductQuickView
                  name={product.title}
                  description={getShortDescription(product.descriptionHtml)}
                  detailUrl={`/products/${product.parentCategorySlug}/${product.handle}`}
                  price={price}
                  images={[product.images[0]]}
                  isNew={product.isNew}
                />
              </li>
            );
          })}
      </ul>
    </Page>
  );
}

export default ProductListPage;
