const prepHtml = html => {
  let preppedHtml = "";

  if (html) {
    const rawHtml = html.match(/.+/g);

    if (rawHtml) {
      preppedHtml = rawHtml.join("");
    }
  }

  return preppedHtml;
};

export const getShortDescription = html => {
  let shortDescription = "";

  if (html) {
    let desc = prepHtml(html);
    desc = desc.match(/\[shortDesc\].+\[\/shortDesc\]/g);

    if (desc) {
      shortDescription = desc
        .join("")
        .replace(/\[shortDesc\]|\[\/shortDesc\]/g, "");
    }
  }

  return shortDescription;
};

export const getLongDescription = html => {
  let longDescription = "";

  if (html) {
    let desc = prepHtml(html);
    desc = desc.match(/\[longDesc\].+\[\/longDesc\]/g) || "";

    if (desc) {
      longDescription = desc
        .join("")
        .replace(/\[longDesc\]|\[\/longDesc\]/g, "");
    }
  }

  return longDescription;
};
