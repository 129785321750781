import { useEffect, useContext } from "react";
import cookie from "js-cookie";

import AppContext from "../app-context";
import client from "../shopfiy-client";

function useCheckout() {
  const globalContext = useContext(AppContext);
  const { lineItems } = globalContext.checkout || {};
  let itemCount = 0;

  if (Array.isArray(lineItems) && lineItems.length > 0) {
    itemCount = lineItems.reduce((acc, item) => {
      if (item.quantity) return acc + item.quantity;
    }, 0);
  }

  async function getCheckout() {
    let cart = null;
    const cartId = cookie.get("cartId");

    try {
      if (cartId) {
        cart = await client.checkout.fetch(cartId);
      } else {
        cart = await client.checkout.create();
        cookie.set("cartId", cart.id);
      }

      globalContext.setCheckout(cart);
    } catch (err) {
      console.error("Error getting a cart | ", err);
    }
  }

  async function addToCart(productId, quantity) {
    try {
      if (globalContext.checkout && productId && quantity) {
        const resp = await client.checkout.addLineItems(
          globalContext.checkout.id,
          [{ variantId: productId, quantity }]
        );

        globalContext.setCheckout(resp);
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function updateQuantity(productId, quantity) {
    try {
      if (globalContext.checkout && productId && quantity) {
        const resp = await client.checkout.updateLineItems(
          globalContext.checkout.id,
          [{ id: productId, quantity }]
        );

        globalContext.setCheckout(resp);
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function removeItem(productId) {
    try {
      if (globalContext.checkout && productId) {
        const resp = await client.checkout.removeLineItems(
          globalContext.checkout.id,
          [productId]
        );

        globalContext.setCheckout(resp);
      }
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    getCheckout();
  }, []);

  return {
    addToCart,
    updateQuantity,
    removeItem,
    checkout: globalContext.checkout,
    itemCount
  };
}

export default useCheckout;
