import React from "react";

import { Link } from "react-router-dom";

import "./button.scss";

const Button = ({
  className = "",
  primary,
  href,
  children,
  isDisabled = false,
  onClick = null
}) => {
  let classNames = `btn ${primary ? "btn--primary" : ""} ${
    isDisabled ? "btn--disabled" : ""
  } ${className}`;

  if (href && /http:\/\/|https:\/\/|www\./.test(href)) {
    return (
      <a
        className={classNames}
        href={href}
        target="_blank"
        onClick={onClick}
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  } else if (href) {
    return (
      <Link className={classNames} to={href} onClick={onClick}>
        {children}
      </Link>
    );
  } else {
    return (
      <button
        className={classNames}
        type="button"
        disabled={isDisabled}
        onClick={onClick}
      >
        {children}
      </button>
    );
  }
};

export default Button;
