import React from "react";

import Page from "../../components/page/page";
import Button from "../../components/button/button";

import "./error-page.scss";

function ErrorPage() {
  return (
    <Page title="Woops">
      <p>We can't find the page you're looking for.</p>
      <div>
        <Button primary href="/">
          Go Back
        </Button>
      </div>
    </Page>
  );
}

export default ErrorPage;
